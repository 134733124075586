import { Box, Button, Flex, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import BannerItem from "./banner-item"

const StyledBlockButton = styled(Button).attrs((props) => ({
  forwardedAs: Link,
  height: { _: "70px", desktop: "138px" },
  textDecoration: "underline",
  width: "100%",
  ...props,
}))`
  &:hover {
    text-decoration: none;
    color: ${(props) => props.color};
  }
`

const LandingHeroBanner = ({ element, homepage, lazyload }) => {
  const { contain, style } = element
  const { cta, items, url } = element.data

  return (
    <Box containWide={contain} {...style}>
      <Flex flexDirection={{ _: "column", desktop: "row" }}>
        {items.map((item, i) => (
          <BannerItem
            element={element}
            homepage={homepage}
            item={item}
            key={`hero-banner-item-${i}`}
            lazyload={lazyload}
          />
        ))}
      </Flex>
      {cta && url && (
        <StyledBlockButton data-lc="hero-banner" to={url} {...style?.cta}>
          <Typography
            fontWeight="600"
            letterSpacing="0.02em"
            textTransform="uppercase"
            variant="body"
          >
            {cta}
          </Typography>
        </StyledBlockButton>
      )}
    </Box>
  )
}

LandingHeroBanner.propTypes = {
  element: PropTypes.object,
  homepage: PropTypes.bool,
  lazyload: PropTypes.bool,
}

export default LandingHeroBanner
