import { faDiamond } from "@jewlr/sharp-solid-svg-icons/faDiamond"
import {
  Box,
  Button,
  Flex,
  FontAwesomeIcon,
  Typography,
} from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import ResponsiveVideo from "areas/landing/elements/landing-responsive-video"
import Image from "components/landing/image"
import { isTestMode } from "helpers/application"

const FlexContainer = styled(Flex).attrs((props) => ({
  flexDirection: "column",
  height: "100%",
  justifyContent: "flex-end",
  left: 0,
  pb: { _: "20px", desktop: "40px", widescreen: "60px" },
  position: "absolute",
  pt: { _: "20px", desktop: "40px", widescreen: "60px" },
  px: { _: "20px", desktop: "40px", widescreen: "60px" },
  top: 0,
  width: "100%",
  ...props,
}))``

const StyledButton = styled(Button).attrs((props) => ({
  bg: "primary",
  color: "white",
  forwardedAs: Link,
  height: "44px",
  px: 2,
  width: "fit-content",
  ...props,
}))``

const BannerLink = styled(Link)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`

const LandingBannerItem = ({ element, homepage, item, lazyload }) => {
  const { image, style, text_container_style, texts, url, video } = item

  const handleClick = () => {
    if (!isTestMode) {
      dataLayer.push({
        event: "clicked-homepage-banner",
      })
    }
  }

  return (
    <Box position="relative" width="100%" {...style}>
      {url && (
        <BannerLink
          aria-label={image && (image.title || image.alt)}
          data-lc="hero-banner-item"
          to={url}
          {...(homepage && { onClick: handleClick })}
        />
      )}
      {image && <Image image={image} lazyload={lazyload} />}
      {video && (
        <ResponsiveVideo id={element.id} lazyload={lazyload} video={video} />
      )}
      {texts?.length > 0 && (
        <FlexContainer {...text_container_style}>
          {texts.map((text, i) => {
            if (text.type === "cta" && url) {
              return (
                <StyledButton
                  aria-hidden
                  data-lc="hero-banner-item"
                  key={`${element.id}-banner-item-${i}`}
                  tabIndex="-1"
                  to={url}
                  {...text.style}
                >
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: text.text,
                    }}
                    fontWeight="600"
                    textTransform="uppercase"
                    variant="body-sm"
                  />
                </StyledButton>
              )
            } else if (text.type === "confetti") {
              return (
                <Flex
                  alignItems="center"
                  fontSize={{ _: "30px", tablet: "42px" }}
                  fontWeight="700"
                  key={`${element.id}-banner-item-${i}`}
                  lineHeight={{ _: "1", tablet: "1.2" }}
                  textTransform="uppercase"
                  {...text.style}
                >
                  <FontAwesomeIcon
                    fontSize={{ _: "14px", tablet: "24px" }}
                    icon={faDiamond}
                    mr="4px"
                  />
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: text.text,
                    }}
                  />
                </Flex>
              )
            }

            const variant =
              text.type === "title"
                ? "display-md"
                : text.type === "subtitle"
                ? "h1"
                : "body-lg"

            return (
              <Typography
                dangerouslySetInnerHTML={{
                  __html: text.text,
                }}
                key={`${element.id}-banner-item-${i}`}
                variant={variant}
                {...text.style}
              />
            )
          })}
        </FlexContainer>
      )}
    </Box>
  )
}

LandingBannerItem.propTypes = {
  element: PropTypes.object,
  homepage: PropTypes.bool,
  item: PropTypes.object,
  lazyload: PropTypes.bool,
}

export default LandingBannerItem
